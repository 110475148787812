import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header = <></>

  if (location.pathname === rootPath) {
    header = (<></>)
  } else {
    header = (
      <h5 className="site-title">
        <Link to={`/`}>{title} &middot; Home</Link>
      </h5>
    )
  }
  return (
    <>
      <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Patua+One&display=swap" rel="stylesheet"/>
      </Helmet>
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: `600px`,
          padding: `2rem`,
        }}
      >
        {header}
        <main>{children}</main>
        <footer>
          <small>
            © {new Date().getFullYear()} Andrew J Wright. Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </small>
        </footer>
      </div>
    </>
  )
}

export default Layout
